//@ts-nocheck
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Layout } from "antd";
import SideMenuView from "./component/sidebar/SideMenuView";
import FooterView from "./component/footer/FooterView";
import HeaderView from "./component/header/HeaderView";
import AnimatingComponent from "../../hoc/AnimatingComponent";
import { useHistory, useLocation, useParams } from "react-router-dom";
import BottomBar from "./component/bottom-bar/BottomBar";
import DynamicInfoModal from "../../components/DynamicInfoModal";
import AuthService from "../../services/api/AuthService";

const LSKey = "onboarding_user";
const { Sider, Content } = Layout;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return [size[0] <= 768, size];
}

const LayoutView = ({ children }: any) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const [collapsed, setCollapsed] = useState(window.innerWidth <= 768);
  const [pageHeader, setPageHeader] = useState("");
  const [headerHeight, setHeaderHeight] = useState(153);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [isWidthSmall] = useWindowSize();
  const [user, setUser]: any = useState();
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const [infoVisible, setInfoVisible] = useState(false);
  const [infoConfig, setInfoConfig] = useState({});

  const hideSidebarPaths = ["/"];
  const hideHeaderPaths = ["swiping-game"];

  const handleScrollEvent = useCallback(() => {
    setTriggerUpdate(Math.random() * 10);
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("user") &&
      location.pathname !== "/terms-and-conditions" &&
      location.pathname !== "/privacy-policy" &&
      !location.pathname.includes("onboarding")
    ) {
      onLogout();
    } else {
      const data: any = localStorage.getItem("user");
      setUser(JSON.parse(data));
    }

    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && window.innerWidth > 768) {
      setCollapsed(false);
    }
  }, [user]);

  useEffect(() => {
    if (user && window.innerWidth > 768) {
      setCollapsed(false);
    }
  }, [window.innerWidth]);

  const onLogout = () => {
    const { booking } = history.location.state || {};
    if (!booking?.is_temporary) {
      localStorage.clear();
      history.push("/");
    }
  };

  const toggleCollapse = (collapsed: boolean) => {
    if (window.innerWidth <= 768) {
      setCollapsed(collapsed);
    }
  };

  const renderSideBar = () => {
    const splitPath = location.pathname.split("/");

    if (location.pathname.includes("onboarding")) {
      return null;
    }

    if (
      !isWidthSmall &&
      (location.pathname === "/" ||
        // location.pathname === "/home" ||
        location.pathname === "/privacy-policy" ||
        location.pathname === "/terms-and-conditions" ||
        location.pathname === "/success")
    ) {
      return null;
    }
    return (
      <Sider
        width={350}
        theme="light"
        trigger={null}
        className="sidebar"
        collapsible
        collapsedWidth={0}
        collapsed={collapsed}
        onCollapse={toggleCollapse}
      >
        <SideMenuView siderCollapsed={collapsed} collapseToggle={toggleCollapse} style={{ background: "#ffffff" }} />
      </Sider>
    );
  };

  const onSkipOnboarding = () => {
    setInfoVisible(true);
    setInfoConfig({
      title: "Skip this Step?",
      content: "You can update your profile information later in Settings.",
      icon: "warning",
      btnText: "SKIP",
      confirmMode: true,
      confirmAction: async () => {
        const pathnames = history.location.pathname.split("/");
        const id = pathnames[pathnames.length - 1];
        // await props.onSkip();
        const { invitation_token } = history.location.state || {};
        let api = new AuthService();

        const prefValues: any = {
          preferences: [],
          price_rating: "$",
        };

        const user = await api.onBoardingPreferences(id, prefValues);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.removeItem(LSKey);

        if (invitation_token) {
          return history.replace("/date-nights");
        }

        return history.replace("/");
      },
      width: 310,
    });
    return;
  };

  const renderHeader = () => {
    const splitPath = location.pathname.split("/");

    if (
      (splitPath.includes("swiping-game") ||
        splitPath.includes("plan-summary") ||
        splitPath.includes("send-invite") ||
        splitPath.includes("date-nights") ||
        splitPath.includes("browse-dates") ||
        splitPath.includes("settings") ||
        splitPath.includes("profile") ||
        splitPath.includes("match-date-nights") ||
        splitPath.includes("notifications") ||
        splitPath.includes("plan-date-public") ||
        splitPath.includes("browse-dates-public") ||
        splitPath.includes("browse-dates-booking") ||
        splitPath.includes("success") ||
        splitPath.includes("plan-date")) &&
      !isWidthSmall
    ) {
      return null;
    }

    return (
      <HeaderView
        onLoadHeight={setHeaderHeight}
        closeDropdown={triggerUpdate}
        siderCollapsed={collapsed}
        collapseToggle={toggleCollapse}
        onSkipOnboarding={onSkipOnboarding}
        pageHeader={pageHeader}
      />
    );
  };

  const renderFooter = () => {
    const splitPath = location.pathname.split("/");

    if (
      splitPath.includes("swiping-game") ||
      splitPath.includes("date-nights") ||
      splitPath.includes("browse-dates") ||
      splitPath.includes("plan-date") ||
      splitPath.includes("settings") ||
      splitPath.includes("profile") ||
      splitPath.includes("notifications") ||
      splitPath.includes("match-date-nights") ||
      splitPath.includes("plan-date-public") ||
      splitPath.includes("browse-dates-public") ||
      splitPath.includes("browse-dates-booking") ||
      splitPath.includes("onboarding")
    ) {
      return null;
    }

    if (splitPath.includes("date-nights") && isWidthSmall) {
      return null;
    }

    return <FooterView />;
  };

  const renderBottomBar = () => {
    const splitPath = location.pathname.split("/");

    if (
      (splitPath.includes("swiping-game") ||
        splitPath.includes("date-nights") ||
        splitPath.includes("browse-dates") ||
        splitPath.includes("match-date-nights") ||
        (user && location.pathname === "/") ||
        splitPath.includes("plan-date")) &&
      isWidthSmall
    ) {
      return <BottomBar />;
    }

    return null;
  };

  const pathnames = history.location.pathname.split("/");
  const swiping_game_path = pathnames[pathnames.length - 1] === "swiping-game";
  const date_nights_path = pathnames[pathnames.length - 1] === "date-nights"
  const date_ideas_path = pathnames[pathnames.length - 1] === "browse-dates";
  const date_ideas_path_activity = pathnames[pathnames.length - 1] === "Activity"
  const date_ideas_path_foodAndDrink = pathnames[pathnames.length - 1] === "Food and Drink";
  const date_ideas_public_path = pathnames[pathnames.length - 1] === "browse-dates-public";

  const isHidden =
    swiping_game_path ||
    date_nights_path ||
    date_ideas_path ||
    date_ideas_public_path ||
    date_ideas_path_activity ||
    date_ideas_path_foodAndDrink;

  let smallWidthStyles =
    isWidthSmall &&
    (location.pathname.includes("swiping-game") ||
      location.pathname.includes("match-date-nights") ||
      location.pathname.includes("browse-dates") ||
      location.pathname.includes("date-nights") ||
      location.pathname.includes("plan-date"))
      ? {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 73,
          paddingTop: 64,
        }
      : location.pathname === "/" && isWidthSmall
      ? {
          paddingBottom: 15,
          paddingTop: 64,
        }
      : {};

  if (swiping_game_path && isWidthSmall) {
    smallWidthStyles = {
      paddingBottom: 0,
      paddingTop: 0,
      minHeight: "auto",
      // height: `calc(100vh - (64px + 73px))`,
      height: window.innerHeight,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      // marginTop: 68
    };
  }

  const child = React.Children.map(children, (child, index: number) => {
    // return <AnimatingComponent>{React.cloneElement(child, {setPageHeader: setPageHeader })}</AnimatingComponent>;
    return React.cloneElement(child, { setPageHeader: setPageHeader, onSkipOnboarding });
  });

  return (
    <Layout
      style={{
        maxWidth: "100vw",
        overflowX: "hidden",
        maxHeight: swiping_game_path ? "100vh" : "unset",
        overflowY: isHidden ? "hidden" : "unset",
      }}
      hasSider
    >
      {/* { (!isWidthSmall && !user) ?  renderSideBar() : user && renderSideBar()} */}
      {renderSideBar()}
      <Layout className="main-layout">
        {renderHeader()}
        {headerHeight ? (
          <Content
            style={{
              // padding: 24,
              // minHeight: 'calc(100vh - 56px)',
              // maxHeight: 'calc(100vh - 56px)',
              // paddingTop:  headerHeight,
              minHeight: "100vh",
              position: "relative",
              paddingBottom: 15,
              paddingTop: 15,
              ...smallWidthStyles,
            }}
          >
            {child}
          </Content>
        ) : null}
        {renderFooter()}
        {renderBottomBar()}
      </Layout>
      <DynamicInfoModal {...infoConfig} visible={infoVisible} setVisible={setInfoVisible} />
    </Layout>
  );
};

export default LayoutView;
