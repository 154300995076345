import { Col, Row, Space } from 'antd';
import React from 'react';
import { HeartFilled } from '@ant-design/icons';

import "./BottomBar.less";
import { BottomBarIconDateInpsiration, BottomBarIconHeart, BottomBarIconHome, BottomBarIconMask } from '../../../../components/icons/Icons';
import { useHistory } from 'react-router-dom';
import AuthService from '../../../../services/api/AuthService';

const authService = new AuthService();
const BottomBar = () => {
    const history = useHistory()
    const isLoggedIn = authService.getUser()
    const isActive = (path: string) => history.location.pathname.includes(path) 
    const currentActivePath = (path: string) => isActive(path) ? " active" : ""
    const isHomeActive = history.location.pathname === "/"
    const isHome = isHomeActive ? " active" : ""
    return <Row className='bottom-bar-container' justify='space-around' align='middle'>
                <Col className='c-pointer' onClick={() => history.push(isLoggedIn ? "/" : "/")}>
                    <BottomBarIconHome className={"bottom-bar-icon" + isHome} size={isHomeActive ? 32 : 28 }/>
                </Col>
                <Col className='c-pointer' onClick={() => history.push(isLoggedIn ? "/plan-date" : "/login")}>
                    <BottomBarIconMask className={"bottom-bar-icon" + currentActivePath("plan-date")} size={isActive("plan-date") ? 38 : 38 }/>
                </Col>
                {/* <Col className='c-pointer' onClick={() => history.push(isLoggedIn ? "/match-date-nights" : "/login")}>
                    <BottomBarIconDateInpsiration className={"bottom-bar-icon" + (currentActivePath("date-inspirations") || currentActivePath("get-inspired"))} size={(isActive("date-inspirations") || isActive("get-inspired")) ? 36 : 28}/>
                </Col> */}
                <Col className='c-pointer' onClick={() => history.push(isLoggedIn ? "/date-nights" : "/login")}>
                    <BottomBarIconHeart className={"bottom-bar-icon" + currentActivePath("date-nights")} size={isActive("date-nights") ? 32 : 28}/>
                </Col>
            </Row>
}

export default BottomBar