import sdPlaceholderImage from '../assets/png/sd-placeholder.png';

/**
 * App configuration
 */
class AppConfig {
  // API Configuration
  static API_URL = process.env.REACT_APP_API_ENDPOINT || 'https://sd-api.ark.ph/api';
  static DEFAULT_TIMEZONE = process.env.REACT_APP_DEFAULT_TIMEZONE || 'Asia/Dubai';
  static SOCKET_URL = process.env.REACT_APP_API_SOCKET_URL || 'https://sd-notif.ark.ph/';
  
  // Authentication Configuration
  static FB_APP_ID = process.env.REACT_APP_FB_APP_ID || '';
  static GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
  static GOOGLE_SECRET_KEY = process.env.REACT_APP_GOOGLE_SECRET_KEY || '';
  
  // Limits and Settings
  static SWIPEABLE_MAX_LIMIT = parseInt(process.env.REACT_APP_SWIPEABLE_MAX_LIMIT!, 10) || 20;
  static MINIMUM_AGE = parseInt(process.env.REACT_APP_MINIMUM_AGE!, 10) || 18;
  static ALLOW_DUBAI_NUMBERS_ONLY = Boolean(parseInt(process.env.REACT_APP_ALLOW_DUBAI_NUMBERS_ONLY!, 10)) || false;
  static PRICE_RATINGS = ["$", "$$", "$$$"];
  static SD_PLACEHOLDER_IMAGE = sdPlaceholderImage;
  static DEFAULT_COUNTRY = process.env.REACT_APP_DEFAULT_COUNTRY || 'United Arab Emirates';
  
  // Stripe Configuration
  static STRIPE_PUBLISHABLE = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
  
  // Frontend URL
  static FE_URL = process.env.REACT_APP_FE_URL || 'https://sd.ark.ph';
}

export default AppConfig;
