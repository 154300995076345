import { CloseOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Col, Menu, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import socketIOClient from "socket.io-client";
import logo_icon from "../../../../assets/svg/logo-dark.svg";
import logo from "../../../../assets/svg/logo-text-row.svg";
// MachineIcon
import {
  BurgerIcon,
  IconRight
} from "../../../../components/icons/Icons";
import AuthService from "../../../../services/api/AuthService";

import APPConfig from "../../../../config";
import ManageNotificationService from "../../../../services/api/ManageNotificationService";
import useHasUpdated from "../../../../services/custom-hooks/useHasUpdated";
import "./SideMenuView.less";

const getUser = () => {
  const data: any = localStorage.getItem("user");
  return data;
};

const socket = socketIOClient(APPConfig.SOCKET_URL, { transports: ["websocket", "polling", "flashsocket"] });
const notificationService = new ManageNotificationService();

const SideMenuView = ({ siderCollapsed, collapseToggle }: any) => {
  const location = useLocation();
  const history = useHistory();
  let [user, setUser]: any = useState();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [notifsDataCount, setNotifsDataCount]: any = useState([]);
  const [hasUpdated] = useHasUpdated();
  const auth = new AuthService();

  const data = JSON.parse(getUser());
  useEffect(() => {
    const keyPath = keyPathTransformer(`/${location.pathname.split("/")[1]}`);
    setCurrentPath(keyPath);

    // verifyUser()
  }, [location.pathname]);

  const verifyUser = async () => {
    if (data) {
      const isDeleted = await auth.verifyUser(data._id);
      if (isDeleted) onLogout();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const data: any = localStorage.getItem("user");
      setUser(JSON.parse(data));
      user = JSON.parse(data);
      onLoadNotifications();
      socket.on("customer_notifications_" + user?.customer?._id, () => {
        onLoadNotifications();
      });

      return () => {
        socket.disconnect();
      };
    }

    if (window.innerWidth > 768) {
      collapseToggle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (localStorage.getItem("user")) {
      const data: any = localStorage.getItem("user");
      setUser(JSON.parse(data));
    }
  }, [hasUpdated]);

  const keyPathTransformer = (value: string) => {
    if (value.includes("get-inspired")) {
      value = "/match-date-nights";
    }

    switch (value) {
      case "/": {
        return "/";
      }
      case "/date-nights": {
        return "/date-nights";
      }
      case "/plan-date": {
        return "/plan-date";
      }
      case "/browse-dates": {
        return "/browse-dates"
      }
      case "/settings": {
        return "/settings";
      }

      case "/match-date-nights": {
        return "/match-date-nights";
      }

      case "/notifications": {
        return "/notifications";
      }
      
      case "/plan-date-public":{
        return "/plan-date-public"
      }

      case "/browse-dates-public":{
        return "/browse-dates-public"
      }

      default: {
        return "";
      }
    }
  };

  const onLogout = async () => {
    collapseToggle(!siderCollapsed);
    if (user) {
      const authService = new AuthService();
      await authService.logout(user?._id);
    }
    localStorage.clear();
    window.location.reload();
  };

  const onLoadNotifications = async () => {
    const result = await notificationService.getAllNotification();
    const notifsCount = result?.unread_count;
    setNotifsDataCount(notifsCount);
  };

  const profile = () => {
    history.push("/profile");
  };

  const onToggleMenus = (action: string) => {
    if (window.innerWidth <= 768) {
      collapseToggle(!siderCollapsed);
    }
    setCurrentPath(action);
    history.push(action);
  };

  const menu = (
    <Menu>
      <Menu.Item key="2" className="link-secondary">
        <span onClick={() => onLogout()} className="c-pointer fw-600">
          <LogoutOutlined /> Log Out
        </span>
      </Menu.Item>
    </Menu>
  );

  const profileInfo = [
    {
      name: data ? data.first_name : null,
      type: data ? data.user_type : null,
      image: <UserOutlined />,
    },
  ];

  return (
    <Row className="sidebar-root">
      <Row align="middle" justify="center" style={{ width: "100%", padding: `25px 24px`, paddingBottom: 30, overflow: "hidden" }}>
        {!siderCollapsed ? (
          <>
            <Col className="logo-container" flex={1}>
              <img style={{ cursor: "pointer" }} onClick={() => onToggleMenus("/")} src={logo} alt="Secret Dates" width="200" height="60" />
            </Col>
            <Col className="visible-md" style={{ alignSelf: "baseline" }}>
              <CloseOutlined
                className="sidebar-trigger-icon c-pointer visible-xs"
                style={{ marginTop: 5 }}
                onClick={() => collapseToggle(!siderCollapsed)}
              />
            </Col>
          </>
        ) : (
          <Space align="center" style={{ height: 44 }}>
            <img style={{ cursor: "pointer" }} onClick={() => onToggleMenus("/")} src={logo_icon} alt="Secret Dates" width="45" height="40" />
            <BurgerIcon className="c-pointer  visible-xs" />
          </Space>
        )}
      </Row>
      <Menu
        mode="inline"
        selectedKeys={[currentPath]}
        defaultSelectedKeys={[currentPath === "/" ? "/" : currentPath]}
        style={{ color: "#FFFFFF", border: 0 }}
      >
        {user ? (
          <>
            {window.innerWidth <= 768 ? (
              <>
                <Menu.Item key="/" onClick={() => onToggleMenus("/")}>
                  <span>Home</span>
                </Menu.Item>
                <Menu.Item key="/plan-date" onClick={() => onToggleMenus("/plan-date")}>
                  <span>Plan a Date</span>
                </Menu.Item>
                <Menu.Item key="/match-date-nights" onClick={() => onToggleMenus("/match-date-nights")}>
                  <span>Match a Date</span>
                </Menu.Item>
                <Menu.Item key="/browse-dates" onClick={() => onToggleMenus("/browse-dates")}>
                  <span>Browse Dates</span>
                </Menu.Item>
                <Menu.Item key="/date-nights" onClick={() => onToggleMenus("/date-nights")}>
                  <span>My Date Nights</span>
                </Menu.Item>
              </>
            ) : (
              <>
                <Menu.Item key="/plan-date" onClick={() => onToggleMenus("/plan-date")}>
                  <span>Plan a Date</span>
                </Menu.Item>
                <Menu.Item key="/match-date-nights" onClick={() => onToggleMenus("/match-date-nights")}>
                  <span>Match a Date</span>
                </Menu.Item>
                <Menu.Item key="/browse-dates" onClick={() => onToggleMenus("/browse-dates")}>
                  <span>Browse Dates</span>
                </Menu.Item>
                <Menu.Item key="/date-nights" onClick={() => onToggleMenus("/date-nights")}>
                  <span>My Date Nights</span>
                </Menu.Item>
              </>
            )}
            <Menu.Item key="/notifications" onClick={() => onToggleMenus("/notifications")}>
              <Space align="center">
                <span>Notifications</span>
                <span>
                  <Badge className="sd-badge" count={notifsDataCount} />
                </span>
              </Space>
            </Menu.Item>
            <Menu.Item key="/settings" onClick={() => onToggleMenus("/settings")}>
              <span>Settings</span>
            </Menu.Item>
            <Menu.Item key="/logout" onClick={onLogout}>
              <span>Logout</span>
            </Menu.Item>
            <Row align="middle" className="sidebar-bottom-container" style={{ borderTop: "0.5px solid rgba(178, 161, 161,0.3)" }}>
              <Col span={4}>
                <Row align="middle">
                  {user?.customer?.customer_image ? (
                    <Avatar className="sd-user-avatar" src={user?.customer?.customer_image} />
                  ) : (
                    <Avatar className="sd-user-avatar placeholder" icon={<UserOutlined />} />
                  )}
                </Row>
              </Col>
              <Col span={19}>
                <Row align="middle">
                  <Col>
                    <p className="text-white" style={{ margin: 0, marginRight: 0, fontSize: 16 }}>
                      Hi,&nbsp;
                    </p>
                  </Col>
                  <Col span="19">
                    <p className="text-primary" style={{ margin: 0, fontSize: 16 }}>
                      {" "}
                      {user?.customer?.name || "-"}
                    </p>
                  </Col>
                  <Col span="2">
                    <Button type="primary" shape="default" icon={<IconRight />} style={{ height: 26 }} onClick={() => onToggleMenus("/profile")} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <>
            {location.pathname.includes("plan-date-public") ? (
              <Menu.Item key="/plan-date-public">
                <span>Plan a Date</span>
              </Menu.Item>
            ) : location.pathname.includes("browse-dates-public") ? (
              <Menu.Item key="/browse-dates-public">
                <span>Browse Dates</span>
              </Menu.Item>
            ) : (
              <Menu.Item key="/match-date-nights">
                <span>Match a Date</span>
              </Menu.Item>
            )}
            <Row justify="center" align="middle" className="sidebar-bottom-container">
              <Col>
                <Row align="middle" className="login-div">
                  <a className="logintxt font-25 fw-600 text-white" href="/login">
                    LOGIN
                  </a>
                </Row>
              </Col>
              <Col>
                <Row align="middle">
                  <Button type="primary" htmlType="submit" className="join-now-btn" onClick={() => history.push("/register")}>
                    JOIN NOW
                  </Button>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Menu>
      {!siderCollapsed ? <div className="sidebar--overlay" onClick={() => collapseToggle(!siderCollapsed)}></div> : null}
    </Row>
  );
};

export default SideMenuView;
